/*--------------------------------------------------------------
# Fonts
--------------------------------------------------------------*/
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

legend {
  float: unset;
}
.centered {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transform: -webkit-translate(-50%, -50%);
  transform: -moz-translate(-50%, -50%);
  transform: -ms-translate(-50%, -50%);
}

.MuiAvatarGroup-avatar {
  width: 30px;
  height: 30px;
}
.image-hov:hover {
  opacity: 0.7;
}

.card-hov:hover {
  opacity: 0.7;
}

.list-hov:hover {
  background-color: rgba(0, 171, 85, 0.08);
}

/* .MuiTableCell-body{
    padding-top: 0px;
    padding-bottom: 0px;
} */

.onDrag {
  background-color: rgba(0, 171, 85, 0.08);
}

.not-drag {
  background-color: white;
}

.search-input:focus {
  outline: 1px solid rgba(0, 171, 85, 0.08);
}
/* .search-input {
    background-color:white;
} */

.input-field {
  padding-right: 15px;
  padding-top: 15px;
  padding-left: 15px;
  padding-bottom: 5px;
  /* position: absolute;
    background-color: white; */
  position: fixed;
  background-color: white;
  z-index: 1;
}

.status-circle {
  cursor: pointer;
  height: 25px;
  width: 25px;
  color: #fff;
  border-radius: 50px;
  font-size: 12px;
  text-align: center;
  align-self: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.date-css {
  align-self: center;
}

.main-header {
  border: 1px solid #c5c5c5;
  border-radius: 10px;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
}

.header-detail {
  align-items: center;
}

.header-task {
  display: flex;
  justify-content: space-evenly;
  /* border-right: 1px solid #c5c5c5;
    height: 100%; */
}

.header-date {
  display: flex;
  justify-content: flex-end;
  /* border-right: 1px solid #c5c5c5;
    height: 100%; */
}

.header-progress {
  /* border-right: 1px solid #c5c5c5;
    height: 100%;
    padding-top: 13.5px; */
  display: flex;
  justify-content: space-around;
}

.text-area-task {
  resize: none;
}

.simplebar-wrapper {
  overflow: hidden;
}

.simplebar-content-wrapper {
  overflow: scroll;
}

.task-label {
  font-family: "Montserrat", sans-serif;
}

.upload-button {
  background-color: rgb(54 117 136 / 8%);
}

.pointer {
  cursor: pointer;
}

.support-ticket-wrapper {
  display: flex;
  /* box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px; */
}

.support-profile {
  display: flex;
}

.table-pagination > div {
  align-items: baseline;
}
